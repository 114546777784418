<template>
  <div class="page" :class="{'ratio': devicePixelRatio === 2}">
    <div class="block bar">
      <div class="bar_content">
        <div class="bar_value">
          <div class="title">USER TIPS</div>
          <div class="hint">Dear customer, you are not a regular customer of our AjeGo platform, please apply fora loan through the AjeGo app.</div>
          <div class="text">Download the AjeGo app</div>
          <el-button type="primary">Download AjeHome app</el-button>
        </div>
      </div>
      <div class="bar_img">
        <img v-if="devicePixelRatio == 2" src="@/assets/img/tips_banner@2x.png">
        <img v-else src="@/assets/img/tips_banner.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserTips',
  data() {
    return {
      devicePixelRatio: window.devicePixelRatio,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {}
.page {
  @include pixel-ratio();
  .block {
    text-align: left;
    .block_box {
      display: flex;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
     font-size: 13px;
     color: #999999;
     padding: 10px 0px;
    }
    > .el-button {
      margin-top: 30px;
    }
    .el-form-item {
      display: inline-block;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }

    &.bar {
      position: relative;
      background: white;
      .bar_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .bar_content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .bar_value {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 0px 10px;
          max-width: 1200px;
          margin: 0 auto;
          .hint {
            max-width: 500px;
          }
        }
      }
    }

  }
  @media screen and (max-width: 800px) {
    .block {
      text-align: center;
      .block_box {
        display: inline-block;
      }
      .el-form-item {
        display: block;
      }
      &.bar {
        min-height: 600px;
        .bar_img {
          position: absolute;
          top: 100px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(0deg,rgba(0,0,0,0.00)0%,#ffffff 100%);
          }
          img {
            height: 100%;
            width: auto;
            position: absolute;
            right: 0px;
            transform: translateX(18%);
          }
        }
        .title {
          font-size: 26px;
        }
        .el-button {
          margin-top: 330px;
        }
        .bar_content {
          padding-top: 30px;
          padding-bottom: 20px;
          .bar_value {
            top: 10px;
            transform: none;
          }
        }
      }

    }
  }
}
</style>
